import Terminal from '../Components/Terminal';

export default function ExperienceIndex(){
    return (
        <div className='bg-slate-700 h-screen flex'>
            <div className='h-full w-full md:h-2/4 md:w-3/5 m-auto rounded-md'>
                <Terminal className={"rounded-lg"}/>
            </div>
        </div>
    );
}