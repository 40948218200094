import { useState } from "react";
import Terminal from "./Terminal";

export default function Header(){
    const [showTerminal, setShowTerminal] = useState(false);

    return (
        <div className="relative">
            <div className="bg-slate-800 h-12 flex">
                <div className="flex flex-row rounded-t-lg my-auto">
                    <div className="rounded-full bg-red-600 h-4 w-4 py-2 my-3 mx-1 ml-3 hover:opacity-80" onClick={()=>setShowTerminal(false)}></div>
                    <div className="rounded-full bg-yellow-600 h-4 w-4 py-2 my-3 mx-1 hover:cursor-not-allowed"></div>
                    <div className="rounded-full bg-green-600 h-4 w-4 py-2 my-3 mx-1 hover:opacity-80" onClick={()=>setShowTerminal(true)}></div>
                </div>
                <marquee className="m-auto text-white w-full">Open the terminal</marquee>
            </div>
            { 
                showTerminal &&
                <div className="absolute top-12 h-[40vh] w-full z-10 m-0 p-0">
                    <Terminal setShowTerminal={setShowTerminal} className={"rounded-b-lg"}/>
                </div>
            }
        </div>
    );
}