import TabNavigator from "../Components/Navigator";
import Introduction from "./Introduction";

export default function PortfolioIndex(){
    
    return(
        <div>
            <Introduction />
            <TabNavigator />
        </div>
    );
}