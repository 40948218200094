import { useNavigate } from "react-router-dom"

export default function LandingPage(){

    const navigate = useNavigate();
    
    return(
        <div className="bg-slate-700 w-full h-screen flex">
            <div className="hover:bg-slate-900 transition duration-500 basis-1/2 flex hover:cursor-pointer" onClick={()=>navigate("/portfolio")}>
                <div className="text-white w-1/12 h-12 m-auto">
                    <p className="text-center">Portfolio</p>
                </div>
            </div>

            <div className="hover:bg-slate-900 transition duration-500 basis-1/2 flex hover:cursor-pointer" 
            // onClick={()=>navigate("/experience")}
            >
            <div className="transition duration-500 basis-1/2 flex hover:cursor-not-allowed opacity-40">
                <div className="text-white w-1/12 h-12 m-auto">
                    <p className="line-through text-center">Experience</p>
                </div>
            </div>
            
            </div>
        </div>
    )
}