import React from 'react';
import { Routes, Route } from "react-router-dom";
import ExperienceIndex from './Experience/Pages/Index';
import Home from './Experience/Pages/Home';
import { RecoilRoot } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Header2 from './Experience/Components/Header';
import Breakpoint from './Experience/Components/breakpoint';
import LandingPage from './lander';
import PortfolioIndex from './Portfolio/Pages/Index';
import Internship from './Portfolio/Pages/Internship';
import Projects from './Portfolio/Pages/Projects';
import AboutMe from './Portfolio/Pages/AboutMe';
import { AcademicCapIcon, BookOpenIcon, HomeIcon, UserIcon } from '@heroicons/react/24/outline';


const formatElement = (element) => {
  return <div className="h-[calc(100vh-3rem)] bg-slate-700">{element}</div>
}


export const routes = [
  { path: "/", element: formatElement(<LandingPage />),name:""},
  { path: "/portfolio", element: formatElement(<PortfolioIndex />),name:"Portfolio", icon:<HomeIcon className='w-16 h-16 m-auto p-4' />},
  { path: "/portfolio/aboutme", element: formatElement(<AboutMe />),name:"About Me", icon:<UserIcon className='w-16 h-16 m-auto p-4' />},
  { path: "/portfolio/internship", element: formatElement(<Internship />),name:"Stage", icon:<AcademicCapIcon className='w-16 h-16 m-auto p-4' />},
  { path: "/portfolio/projects", element: formatElement(<Projects />),name:"Projecten", icon:<BookOpenIcon className='w-16 h-16 m-auto p-4'/>},
  { path: "/experience", element: formatElement(<ExperienceIndex />),name:"", icon:""},
  { path: "/home", element: formatElement(<Home />),name:"", icon:""}
];

export const extractPaths = (routes) => {
  const paths = [];

  routes.forEach(route => {
    paths.push(route.path);
    if (route.children) {
      const childPaths = extractPaths(route.children);
      paths.push(...childPaths);
    }
  });
  return paths;
};

function App() {
  const routePaths = extractPaths(routes);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!routePaths.includes(location.pathname)) {
      navigate('/');
    }
  }, [location]);

  return (
    <RecoilRoot>
      {window.location.pathname !== '/' || window.location.pathname.startsWith("/portfolio") && <Header2 />}
      {/* <Breakpoint /> */}
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}/>
        ))}
      </Routes>
    </RecoilRoot>
  );
}

export default App;
