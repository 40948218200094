import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Modal({content, setOpen, open, setContent}){
    document.addEventListener("keydown", (e) => {
        if(e.key === "Escape"){
            setOpen(false);
            setContent(null);
        }
    });
    document.addEventListener("click", (e) => {
        if(e.target.classList.contains("absolute")){
            setOpen(false);
            setContent(null);
        }
    });
    return(
        <>
            {
                open && content &&
                <div className="absolute z-10 bg-slate-900 bg-opacity-40 w-full h-screen flex top-0 left-0">
                    <div className="m-auto bg-white w-[75%] h-[75%] rounded-md">
                        <XMarkIcon className="h-6 w-6 m-2 float-right hover:cursor-pointer" onClick={()=>{
                            setOpen(false);
                            setContent(null);
                        }} />
                        <div className=" h-full overflow-y-auto">
                            {content}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}