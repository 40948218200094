import { useEffect, useState } from "react";
import TabNavigator from "../Components/Navigator";
import CV from "../../assets/CV.pdf";
import Gradient from "../Components/Gradient";

export default function AboutMe(){
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);
    return(
        <div>
           <div className="bg-slate-700 w-full h-full lg:h-screen text-white flex flex-col relative">
                <Gradient />
                <div className="w-full h-full p-10 lg:p-20 z-10">
                    <div className={`text-3xl transition-all duration-300 ${loaded ? "translate-x-0" : "-translate-x-20"}`}>
                        ABOUT ME
                    </div>
                </div>
                <div className="lg:text-right lg:text-balance p-20 z-10 h-full w-full">
                    <div>
                        <p>Hey, ik ben Thomas De Ceuster</p>
                        <p>Student, 20 jaar</p>
                        <p>Full Stack</p>
                    </div>
                    <div className="mt-20">
                        <p>In mijn vrije tijd vind je mij meestal achter mijn computer of laptop, ik heb een passie voor java backend en web development.</p> 
                        <br className="md:hidden"/>
                        <p>Daarnaast speel ik zeker al wel eens graag een spelletje of kijk ik wel eens een serie.</p>
                        <br className="md:hidden"/>
                        <p>Ik sta altijd open om iets nieuws bij te leren!</p>
                        {/* <p>Ik sta altijd open om iets nieuws te leren, zo ben ik begonnen aan een aantal project met Spigot.</p> */}
                    </div>
                    <div className="mt-24 flex flex-col lg:flex-row w-full text-white space-y-4 lg:space-y-0 lg:space-x-4 mb-40">
                        <div className="w-48 lg:w-32 h-10 bg-slate-600 rounded-md select-none flex hover:opacity-80 hover:cursor-pointer m-auto lg:m-0 lg:ml-auto">
                            <a target='_blank' rel="noreferrer" download="CV.pdf" className="m-auto" href={CV} >CV</a>
                        </div>
                        <div className="w-48 lg:w-32 h-10 bg-slate-600 rounded-md select-none flex hover:opacity-80 hover:cursor-pointer m-auto lg:m-0 lg:ml-auto">
                            <a className="m-auto" href="https://www.linkedin.com/in/thomas-de-ceuster-329326252/" target="_blank" rel="noreferrer">LinkedIn</a>
                        </div>
                        <div className="w-48 lg:w-32 h-10 bg-slate-600 rounded-md select-none flex hover:opacity-80 hover:cursor-pointer m-auto lg:m-0 lg:ml-auto">
                            <a className="m-auto" href="https://github.com/ThomasDC92" target="_blank" rel="noreferrer">Github</a>
                        </div>
                        {/* <div className="w-20 h-10 bg-slate-600 rounded-md flex hover:opacity-80 hover:cursor-pointer">
                            <div className="m-auto hover:hidden">CV</div>
                            <ArrowDownTrayIcon className="h-1/2 w-1/2 text-white m-auto ml-auto hidden hover:block" />
                        </div> */}
                    </div>
                </div>
            </div>
            <TabNavigator />
        </div>
    )
}