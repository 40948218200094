import { useNavigate } from 'react-router-dom';
import {routes} from '../../App';
export default function TabNavigator(){
    const path = window.location.pathname;
    const filteredRoutes = routes.filter(r => r.path.startsWith("/portfolio"));

    const navigate = useNavigate();

    return(
        <div className='fixed w-full lg:w-full bottom-0 lg:bottom-6 flex flex-row left-1/2 -translate-x-1/2 text-white justify-center lg:space-x-12 py-4 lg:p-4 hover:opacity-100 opacity-75 transition duration-200 z-20'>
            {
                filteredRoutes.map((r,k)=>{
                    return (
                        <div key={k} className={`w-[${100/filteredRoutes.length}%] h-16 lg:w-40 lg:h-20 flex hover:lg:scale-110 hover:cursor-pointer transition-all select-none lg:rounded-md ${r.path === path ? "bg-yellow-600" : "bg-slate-900"}`} 
                        onClick={()=>{
                            navigate(r.path)
                            window.scrollTo(0,0);
                        }}
                        >
                            <div className='m-auto hidden lg:block'>{r.name}</div>
                            <div className='m-auto lg:hidden block'>{r.icon}</div>
                        </div>
                    )
                })
            }
        </div>
    );
}