import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { extractPaths, routes } from "../../App";
import { useRecoilState } from "recoil";
import { consoleHistoryState } from "../../store";

export default function Terminal({setShowTerminal, className}){
    const path = window.location.pathname;
    const navigate = useNavigate();
    const [commandHistory, setCommandHistory] = useRecoilState(consoleHistoryState);
    const [currentCommandIndex, setCurrentCommandIndex] = useState(-1);
    const [paths, setPaths] = useState([]);

    const getPrefix = () => {
        const prefix = "C:\\" + path.replace("/","") + ">";
        return prefix;
    }

    const comments = [
        "Welcome to thomasdc.dev",
        "Type 'help' to get this list",
        "Type 'ls' to list all the available routes",
        "Type 'clear' to clear the screen",
        // "-------------------------------------------",
        "-".repeat(window.innerWidth/20),
    ];

    useEffect(() => {
        const routepaths = extractPaths(routes);
        setPaths(routepaths);
        console.log(routes)
        const terminal = document.getElementById('terminal');
        comments.forEach(comment => {
            const p = document.createElement('p');
            p.innerHTML = comment;
            terminal.appendChild(p);
        });
    }, []);



    const onInput = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const terminal = document.getElementById('terminal');
            let inputText = e.target.value;
            inputText = inputText.toLowerCase();


            const newLine = document.createElement('div');
            newLine.innerHTML = getPrefix() + inputText;
            terminal.appendChild(newLine);

            if(inputText.startsWith('cd ')){
                const path = inputText.split(' ')[1];
                const currentRoute = window.location.pathname;

                

                if(path === '../'){
                    navigate(-1);
                }
                if(path === '/'){
                    console.log("Navigating to", path);
                    navigate('/');
                }
                if(currentRoute.includes(path)){
                    e.target.value = '';
                    return;
                }
                if(path.startsWith('/') && path !== '/'){
                    paths.forEach(route => {
                        if(route === path){
                            console.log("Navigating to", path);
                            navigate(path);
                        }
                    });
                }else{
                    paths.forEach(route => {
                        if(route === "/"+path){
                            console.log("Navigating to", path);
                            navigate(path);
                        }
                    });
                }
            }

            switch(inputText){
                case "":{
                    const p = document.createElement('p');
                    terminal.appendChild(p);
                    break;
                }
                case "clear":{
                    terminal.innerHTML = '';
                    e.target.value = '';
                    comments.forEach(comment => {
                        const p = document.createElement('p');
                        p.innerHTML = "";
                        terminal.appendChild(p);
                    });
                    break;
                }
                case "cd":{
                    navigate('/');
                    break;
                }
                case "ls":{
                    const p = document.createElement('p');
                    paths.forEach(path => {
                        p.innerHTML += path +"                ";
                    });
                    p.className = "text-yellow-600";
                    terminal.appendChild(p);
                    e.target.value = '';
                    break;
                }
                case "close":{
                    setShowTerminal(false);
                    break;
                }
                case "help":{
                    e.target.value = '';
                    comments.forEach(comment => {
                        const p = document.createElement('p');
                        p.innerHTML = comment;
                        terminal.appendChild(p);
                    });
                    break;
                }
                case "":{
                    break;
                }
                case "":{
                    break;
                }
                default:{
                    const p = document.createElement('p');
                    p.innerHTML = "Command not found";
                    terminal.appendChild(p);
                }

            }


            terminal.scrollTo({
                top: terminal.scrollHeight,
                behavior: 'smooth'
            });
            
            if(inputText !== ""){
                setCommandHistory([...commandHistory, inputText]);
            }
            setCurrentCommandIndex(-1);
            e.target.value = '';
        }
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            if (commandHistory.length > 0) {
                let newIndex;
                if (e.key === 'ArrowUp') {
                    newIndex = currentCommandIndex + 1;
                } else {
                    newIndex = currentCommandIndex - 1;
                }
                newIndex = Math.max(Math.min(newIndex, commandHistory.length - 1), -1);
                setCurrentCommandIndex(newIndex);
                const reversedHistory = [...commandHistory].reverse();
                e.target.value = newIndex === -1 ? '' : reversedHistory[newIndex];
            }
        }
    }



    return(
        <div className={`h-full w-full mx-auto bg-slate-900 ${className}`}>
            <div className="text-white h-full">
                <div id="terminal" className="overflow-y-auto p-2 h-[92%] w-full text-wrap"/>
                <div className="bg-slate-900 border-white border-t h-[8%] flex flex-row px-2 lg:rounded-b-lg">
                        <div className="my-auto mr-1">{getPrefix()}</div>
                       <input type="text" className="bg-slate-900 text-white w-full outline-none" onKeyDown={onInput}/>
                </div>
            </div>
        </div>
    );
}