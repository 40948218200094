const SpigotProject = () =>{
    return(
        <div className="p-8 flex flex-col">
            <p className="text-3xl">Spigot project</p>
            <video src="/assets/spigotplugin.mp4" className="w-[75%] lg:w-1/2 mt-8 m-auto" controls/>
            <div className="mt-4">
                <p>Ik speel in mijn vrije tijd graag videogames, een van mijn all time favorites is Minecraft. Aan de hand van een framework zoals spigot is het
                mogelijk om de servercode van Minecraft aan te passen in Java. Dit maakt het mogelijk om speciale functionaliteiten toe te voegen aan een server met plugins 
                zonder dat andere spelers iets moeten downloaden.</p>
                <br/>
                <p>Voor dit project heb ik een UHC plugin gemaakt, op zich komt de plugin neer op een battle royal. Dat is een genre waar de laatste dat overleeft 
                wint, in het geval van deze plugin geldt dat dus ook.</p>
                <br/>
                <p>Als extra toevoeging zijn er nu ook bepaalde voorwerpen dat de speler kan maken om een voordeel te krijgen, naast dit zijn er ook starter
                 voorwerpen die voor het begin van de match gekozen kunnen worden. Het leaderboard wordt bij het starten van de server aangemaakt in de
                  vorm van een yaml file. Als er al een leaderboard file bestaat wordt die natuurlijk gebruikt.</p>
                  <br/>
                  <p>
                    In de video geef ik een korte demonstratie van de werking van de plugin en de verschillende functionaliteiten, wat niet getoond wordt in de video 
                    is de mogelijkheid om met een commando de verschillende aangepaste recepten te zien voor de speciale voorwerpen.
                  </p>

            </div>
        </div>
    )
}


const Project40 = () =>{
    return(
        <div className="p-8 flex flex-col">
            <p className="text-3xl">Project 4.0</p>
            <video src="/assets/project40.mp4" className="w-[75%] lg:w-1/2 mt-8 m-auto" controls/>
            <div className="mt-4">
                <p>Project 4.0 was een project voor het bedrijf Ride On Track (RoT). We waren met zes leden waarvan 1 ai student, 2 ccs studenten en 3 app studenten.</p>
                <br/>
                <p>De opdracht was om een systeem te maken dat automatisch bepaalde elementen op het spoorweg detecteerd, classificeerd en opslaat in een databank 
                maar ook het weergeven van die data in een uitbreiding van hun web applicatie. Hier ga ik mij vooral focussen op het webapplicatie gedeelte
                 van dit project. Na een lang semester van analyse zijn we tot de conclusie gekomen om een angular app te maken met een java spring backend. </p>
                 <br/>
                 <p>
                    We hadden 1 app student die zich vooral bezig hield met de backend terwijl ik en de andere app student ons focuste op de frontend.
                    Ik heb het meeste tijd gespendeerd aan de pagina's hun layout te matchen aan dat van de testapplicatie van RoT en voor de rest ook met functionaliteiten 
                    op de lijst en history pagina. 
                 </p>
                 <br/>
                 <p>De video hierboven is een bewerkte versie van de promovideo die 1 van de ccs studenten gemaakt had zodat het alleen maar het app gedeelte toont.</p>

            </div>
        </div>
    )
}







export const ProjectContent = {
    SpigotProject,
    Project40
}