import { useEffect, useState } from "react";
import TabNavigator from "../Components/Navigator";
import Modal from "../Components/Modal";
import { ProjectContent } from "../Content/ProjectContent";
import Gradient from "../Components/Gradient";

export default function Projects(){
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(true);
    const [content, setContent] = useState(null);
    useEffect(() => {
        setLoaded(true);
    }, []);
    return(
        <div>
            {/* <div className="bg-slate-700 w-full h-screen text-white flex">
                <div className="bg-gradient-to-r from-slate-600 to-slate-700 w-1/2 h-full p-20 basis-1/4">
                    <div className={`text-3xl transition-all duration-300 ${loaded ? "translate-x-0" : "-translate-x-20"}`}>
                        PROJECTEN
                    </div>
                </div>
                <div className="basis-3/4 p-20 pt-40 flex ">
                    <div className="grid grid-cols-3 grid-rows-3 gap-x-20 ml-auto mr-40">
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">1</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">2</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">3</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">4</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">5</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">6</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">7</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">8</div></div>
                        <div className="w-40 h-40 bg-slate-600 rounded-md flex"><div className="m-auto">9</div></div>
                        
                    </div>
                </div>
            </div> */}
            <div className="bg-slate-700 w-full h-full lg:h-screen text-white flex relative">
                <Gradient />
                <div className="w-full h-full p-10 lg:p-20 z-10">
                    <div className={`text-3xl transition-all mb-8 duration-300 ${loaded ? "translate-x-0" : "-translate-x-20"}`}>
                        PROJECTEN
                    </div>
                    <div className="grid gap-3 grid-cols-1 grid-rows-6 md:grid-rows-3 lg:grid-rows-2 lg:grid md:grid-cols-2 lg:grid-cols-3">
                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer" onClick={()=>{
                            setOpen(true)
                            setContent(ProjectContent.SpigotProject)
                        }}>
                            <div className="m-auto">
                                <img src="/assets/mcspigotlogo.png" alt="Spigot framework logo"></img>
                            </div>
                        </div>
                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer" onClick={()=>{
                            setOpen(true)
                            setContent(ProjectContent.Project40)    
                        }}>
                            <div className="m-auto">
                                <img src="/assets/trainicon.png" alt="Train icon"></img>
                            </div>
                        </div>

                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer">
                            <div className="m-auto">

                            </div>
                        </div>

                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer">
                            <div className="m-auto">

                            </div>
                        </div>
                        
                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer">
                            <div className="m-auto">

                            </div>
                        </div>

                        <div className="w-full bg-slate-600 rounded-md flex hover:cursor-pointer">
                            <div className="m-auto">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TabNavigator />
            <Modal content={content} setOpen={setOpen} open={open} setContent={setContent}/>
        </div>
    )
}