import { useEffect, useState } from "react";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
export default function Introduction() {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);
  return (
    <div className={`bg-slate-700 w-full h-screen text-white p-8 flex flex-col`}>
        <div className="flex flex-col lg:flex-row h-2/6">
            <div className={`w-full h-1/6`}>
                <h1>Portfolio</h1>
            </div>
            <div className="flex w-full h-[80%]">
                <div className="mx-auto lg:m-0 lg:ml-auto">
                    <img src="/assets/pasfoto.jpg" alt="foto" className="rounded-full w-auto h-full lg:mr-24 lg:mt-12"></img>
                </div>
            </div>
        </div>
        <div className={`text-white mt-20 text-center lg:text-left text-6xl transition-all duration-300 ${loaded ? "translate-y-0" : "-translate-y-20"}`}>
            Thomas De Ceuster
        </div>
        <div className="bg-slate-600 bg-gradient-to-t from-slate-700 to-slate-600 rounded-t-md w-full h-1/4 mt-12 flex">
        <div className="mx-auto flex">
                <ArrowDownIcon className="h-10 w-10 m-auto text-white animate-pulse" />
            </div>
        </div>
    </div>
  );
}