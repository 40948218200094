import { atom } from "recoil";


export const isMaximizedState = atom({
    key: 'isMaximized',
    default: false
});

export const consoleHistoryState = atom({
    key: 'consoleHistory',
    default: []
});
