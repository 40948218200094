import TabNavigator from "../Components/Navigator";
import { useEffect, useState } from "react";
import PlanVanAanpak from "../../assets/Plan van aanpak.pdf";
import RealisatieDocument from "../../assets/Realisatiedocument.pdf"
import Reflectie from "../../assets/Reflectie.pdf";
import Gradient from "../Components/Gradient";

export default function Internship(){
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setLoaded(true);
    }, []);
    return(
        <div>
           <div className="bg-slate-700 w-full h-full lg:h-screen text-white flex flex-col relative">
                <Gradient />
                <div className="w-full h-full p-10 lg:p-20 z-10">
                    <div className={`text-3xl transition-all duration-300 ${loaded ? "translate-x-0" : "-translate-x-20"}`}>
                        STAGE
                    </div>
                </div>
                <div className="px-20 z-10 lg:text-right">
                    <div className="flex flex-col">
                        <p className="text-center w-1/4 m-auto lg:ml-auto lg:m-0">Faros stage 2024</p>
                        <p className="text-center w-1/4 m-auto lg:ml-auto lg:m-0">Alloc8</p>
                    </div>
                    <div className="mt-20 text-left">
                        <p>Ik heb mijn stage bij Faros in Geel mogen afleggen. De opdracht was om een zitplaats reservatie app te maken waarbij zowel teams als 
                        individuen reservaties kunnen maken. Voor een gewone gebruiker is dit een enkele of terugkerende reservatie voor één stoel op een bepaald 
                        moment. Voor teams kunnen dit hele segmenten in één keer zijn. Voor segmenten, zitplaatsen en teams te maken heb je een gebouw beheerder
                        nodig. Deze heeft daar zijn eigen dashboard voor inclusief de mogelijkheid om gebruikers te blokkeren en hun reservaties te verwijderen.</p>
                        <br/>
                        <p>Deze opdracht was bedoelt voor twee personen en het was dus een groepswerk. We hebben beide full stack gewerkt maar er was wel een 
                        duidelijk voorkeur bij beide groepsleden, in mijn geval was dat backend.</p>
                        <br/>
                        {/* <div className="bg-slate-600 w-1/12 rounded-md h-8 flex hover:cursor-pointer hover:opacity-80 select-none">
                            <div className="m-auto"> 
                                Media
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-20 mb-40">
                        <p className="text-center w-1/4 m-auto lg:m-0 lg:ml-auto mb-8">Documenten:</p>
                        <div className="flex flex-col justify-end space-y-4">
                            <a className="w-1/2 lg:w-1/4 bg-slate-600 rounded-md m-auto lg:m-0 lg:ml-auto text-center hover:cursor-pointer hover:opacity-80 select-none" target="_blank" rel="noreferrer" download="PlanVanAanpak.pdf" href={PlanVanAanpak}>Plan van aanpak</a>
                            <a className="w-1/2 lg:w-1/4 bg-slate-600 rounded-md m-auto lg:m-0 lg:ml-auto text-center hover:cursor-pointer hover:opacity-80 select-none" target="_blank" rel="noreferrer" download="RealisatieDocument.pdf" href={RealisatieDocument}>Realisatiedocument</a>
                            <a className="w-1/2 lg:w-1/4 bg-slate-600 rounded-md m-auto lg:m-0 lg:ml-auto text-center hover:cursor-pointer hover:opacity-80 select-none" target="_blank" rel="noreferrer" download="Reflectie.pdf" href={Reflectie}>Reflectie</a>
                        </div>
                    </div>
                </div>
            </div>
            <TabNavigator />
        </div>
    )
}